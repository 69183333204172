import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

export class SurveyComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      goHome: "",
    };
    this.redirectHome = this.redirectHome.bind(this);
  }
  redirectHome() {
    this.setState({
      goHome: true,
    });
  }
  render() {
    if (this.state.goHome) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    return (
      <div>
        <p>Survey Completed</p>
        <button onClick={this.redirectHome}>Go Home</button>
      </div>
    );
  }
}

export default SurveyComplete;
