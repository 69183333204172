import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Rating from "@material-ui/lab/Rating";
import { useHistory } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import "./SurveyQuestion.css";
import { CONSTANTS } from "../../utils/constant";

const { TOOLS } = CONSTANTS;
const { API } = CONSTANTS;

const stylesNew = {
  display: "flex",
  flexDirection: "column",
  "& > * + *": {
    marginTop: "1px",
  },
};

class SurveyQuestions extends Component {
  constructor(props) {

    super(props);
    
    // console.log(props);
    this.propData = props.location.state.surveyDetails.Data;
    this.state = {
      currentQuestionType: "",
      blankQuestionAnswer: "",
      isRedirectDashboard: "",
    };
    this.submitQuestion = this.submitQuestion.bind(this);
    this.redirectDashboard = this.redirectDashboard.bind(this);

    this.handleBlankQuestionAnswer = this.handleBlankQuestionAnswer.bind(this);
    this.handleChooeOneQuestionAnswer = this.handleChooeOneQuestionAnswer.bind(
      this
    );
    this.submitQuestiontoServer = this.submitQuestiontoServer.bind(this);
    this.handleChooseFollowingQuestionAnswer = this.handleChooseFollowingQuestionAnswer.bind(
      this
    );
    this.handleRatingQuestion = this.handleRatingQuestion.bind(this);
    this.finishSurvey = this.finishSurvey.bind(this);
    // this.routeChangeCustom = this.routeChangeCustom.bind(this);
  }
  componentDidMount() {
    if (this.propData.surveyDetails) {
      this.setState({
        surveyDetails: this.propData.surveyDetails[0],
        SurveyQuestions: this.propData.surveyQuestions,
        surveySampleDetails: this.propData.surveySampleDetails,
        currentQuestionNo: 0,
        totalQuestions: this.propData.surveyQuestions.length,
        currentQuestionType: this.propData.surveyQuestions[0].queTypeId,
        currentQuestionText: this.propData.surveyQuestions[0].ques_text,
        currentQuestionRatings: this.propData.surveyQuestions[0].questionRating,
        currentQuestionOptions: this.propData.surveyQuestions[0]
          .questionoptions,
        currentQuestionTypeText: this.propData.surveyQuestions[0].questionType,
        currentQuestionId: this.propData.surveyQuestions[0].quesId,
        selectedOption: 0,
        ratingValue: 0,
      });
    }
  }
  redirectDashboard() {
    this.props.history.push("/");
  }
  submitQuestion() {
    console.log("Submit Question");
    console.log(this.state.surveySampleDetails);
    console.log(this.state.currentQuestionType);
    if (this.state.currentQuestionType === 1) {
      let obj = {
        surId: this.state.surveySampleDetails.surveyId,
        surUniq: this.state.surveySampleDetails.surveyUniq,
        Lat: "16.80",
        Lng: "80.16",
        deviceNo: "ABCD",
        sampleUniq: this.state.surveySampleDetails.sampleUniq,
        sampleToken: this.state.surveySampleDetails.sampleToken,
        questionTypeId: this.state.currentQuestionType,
        questionId: this.state.currentQuestionId,
        sampleId: this.state.surveySampleDetails.SampleId,
        answerTxt: this.state.blankQuestionAnswer,
      };
      console.log(obj);
      this.setState({
        blankQuestionAnswer: "",
      });
      this.submitQuestiontoServer(obj);
    } else if (this.state.currentQuestionType === 2) {
      let obj = {
        surId: this.state.surveySampleDetails.surveyId,
        surUniq: this.state.surveySampleDetails.surveyUniq,
        Lat: "16.80",
        Lng: "80.16",
        deviceNo: "ABCD",
        sampleUniq: this.state.surveySampleDetails.sampleUniq,
        sampleToken: this.state.surveySampleDetails.sampleToken,
        questionTypeId: this.state.currentQuestionType,
        questionId: this.state.currentQuestionId,
        sampleId: this.state.surveySampleDetails.SampleId,
        choId: this.state.selectedOption,
      };
      console.log(obj);
      this.setState({
        selectedOption: 0,
      });
      this.submitQuestiontoServer(obj);
    } else if (this.state.currentQuestionType === 3) {
      console.log(this.state.currentQuestionOptions);
      var selectedOptions = this.state.currentQuestionOptions.filter(
        (option) => {
          console.log(option);
          return option.isChecked == true;
        }
      );
      console.log(selectedOptions);
      var selectedOptionsArray = selectedOptions.map(function (el) {
        return el.choId;
      });
      var selectedOptionsText = selectedOptionsArray.join("$@");
      let obj = {
        surId: this.state.surveySampleDetails.surveyId,
        surUniq: this.state.surveySampleDetails.surveyUniq,
        Lat: "16.80",
        Lng: "80.16",
        deviceNo: "ABCD",
        sampleUniq: this.state.surveySampleDetails.sampleUniq,
        sampleToken: this.state.surveySampleDetails.sampleToken,
        questionTypeId: this.state.currentQuestionType,
        questionId: this.state.currentQuestionId,
        sampleId: this.state.surveySampleDetails.SampleId,
        choId: selectedOptionsText,
      };
      console.log(obj);
      this.setState({
        selectedOption: 0,
      });
      this.submitQuestiontoServer(obj);
    } else if (this.state.currentQuestionType === 4) {
      const selectedOptionsArray = this.state.currentQuestionOptions.map(
        function (el) {
          return el.choId;
        }
      );
      const selectedOptionsText = selectedOptionsArray.join("$@");
      const selectedMatchingArray = this.state.currentQuestionOptions.map(
        function (el) {
          return el.machingId;
        }
      );
      const selectedMatchingText = selectedMatchingArray.join("$@");

      let obj = {
        surId: this.state.surveySampleDetails.surveyId,
        surUniq: this.state.surveySampleDetails.surveyUniq,
        Lat: "16.80",
        Lng: "80.16",
        deviceNo: "ABCD",
        sampleUniq: this.state.surveySampleDetails.sampleUniq,
        sampleToken: this.state.surveySampleDetails.sampleToken,
        questionTypeId: this.state.currentQuestionType,
        questionId: this.state.currentQuestionId,
        sampleId: this.state.surveySampleDetails.SampleId,
        choId: selectedOptionsText,
        matchId: selectedMatchingText,
      };
      console.log(obj);
      this.setState({
        selectedOption: 0,
        currentQuestionOptions: 0,
      });
      this.submitQuestiontoServer(obj);
    } else if (this.state.currentQuestionType === 5) {
      let obj = {
        surId: this.state.surveySampleDetails.surveyId,
        surUniq: this.state.surveySampleDetails.surveyUniq,
        Lat: "16.80",
        Lng: "80.16",
        deviceNo: "ABCD",
        sampleUniq: this.state.surveySampleDetails.sampleUniq,
        sampleToken: this.state.surveySampleDetails.sampleToken,
        questionTypeId: this.state.currentQuestionType,
        questionId: this.state.currentQuestionId,
        sampleId: this.state.surveySampleDetails.SampleId,
        ratingValue: this.state.ratingValue,
      };
      console.log(obj);
      this.setState({
        ratingValue: 0,
      });
      this.submitQuestiontoServer(obj);
    }
  }

  submitQuestiontoServer(obj) {
    // console.log(e);
    const _this = this;
    console.log("Start Survey");
    // console.log(TOOLS);
    const url = TOOLS.getUrl(API.answer);
    console.log("URL", url);
    TOOLS.post(
      url,
      obj,
      JSON.parse(localStorage.getItem("Viswas@React_app")).session_Token
    )
      .then(function (responseObj) {
        return responseObj.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.status === "Success") {
          console.log(
            _this.state.currentQuestionNo,
            "---",
            _this.state.SurveyQuestions.length
          );
          if (
            _this.state.currentQuestionNo <
            _this.state.SurveyQuestions.length - 1
          ) {
            _this.setState((prevState) => ({
              currentQuestionNo: prevState.currentQuestionNo + 1,
              currentQuestionType:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .queTypeId,
              currentQuestionText:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .ques_text,
              currentQuestionRatings:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .questionRating,
              currentQuestionOptions:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .questionoptions,
              currentQuestionTypeText:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .questionType,
              currentQuestionId:
                _this.propData.surveyQuestions[prevState.currentQuestionNo + 1]
                  .quesId,
            }));
          } else {
            console.log("All QUestion Done");
            _this.finishSurvey();
          }
          console.log(_this.state);
        }
      });
  }
  finishSurvey() {
    const obj = {
      surId: this.state.surveySampleDetails.surveyId,
      surUniq: this.state.surveySampleDetails.surveyUniq,
      Lat: "16.80",
      Lng: "80.16",
      deviceNo: "ABCD",
      sampleUniq: this.state.surveySampleDetails.sampleUniq,
      sampleToken: this.state.surveySampleDetails.sampleToken,
      sampleId: this.state.surveySampleDetails.SampleId,
    };
    const _this = this;
    console.log("Finish Survey");
    // console.log(TOOLS);
    const url = TOOLS.getUrl(API.finishSurvey);
    console.log("URL", url);
    TOOLS.post(
      url,
      obj,
      JSON.parse(localStorage.getItem("Viswas@React_app")).session_Token
    )
      .then(function (responseObj) {
        return responseObj.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.status === "Success") {
          _this.setState((prevState) => ({
            isRedirectDashboard: true,
          }));
          // _this.routeChangeCustom()
          console.log(_this.state);
        }
      });
  }

  // routeChangeCustom(w){
  //     let path = `surveycomplete`;
  //     let history = useHistory();
  //     history.push(path);
  //   }
  handleBlankQuestionAnswer(e) {
    console.log(e.target.value);
    this.setState({
      blankQuestionAnswer: e.target.value,
    });
  }
  handleChooeOneQuestionAnswer(e) {
    console.log(this);
    console.log(e.target.value);
    const val = e.target.value;

    this.setState({
      selectedOption: parseInt(val),
    });
    console.log(this.state);
  }
  handleChooseFollowingQuestionAnswer(e) {
    console.log(e.target.value);
    console.log(e.target);
    const isChecked = e.target.checked;
    const options = this.state.currentQuestionOptions;
    options.forEach((option) => {
      console.log(option);
      console.log(option.choId, "--", e.target.value);
      console.log(option.choId === e.target.value);
      if (option.choId == e.target.value) {
        console.log("Updating the Option", option.choId);
        option.isChecked = e.target.checked;
      }
    });
    console.log(options);
    this.setState({ currentQuestionOptions: options });
    //console.log(item,'--',isChecked)
    console.log(this.state.currentQuestionOptions);
  }
  handleMatchingQuestion(selectedoption, e) {
    console.log(selectedoption);
    console.log(e.target.value);
    const options = this.state.currentQuestionOptions;
    options.forEach((option) => {
      console.log(option);
      console.log(option.choId, "--", e.target.value);
      console.log(option.choId === e.target.value);
      var selectedOptions = this.state.currentQuestionOptions.filter(
        (option) => {
          console.log(option);
          return option.machingId;
        }
      );
      console.log(selectedOptions);
      var selectedOptionsArray = selectedOptions.map(function (el) {
        return el.machingId;
      });
      console.log(selectedOptionsArray);
      //   if( option.choId == selectedoption.choId && selectedOptionsArray.indexOf(e.target.value) === -1){

      //     if (option.choId == selectedoption.choId)
      //     {
      //         console.log("Updating the Option",option.choId)
      //         option.machingId =  e.target.value
      //     }
      //     else
      //     {
      //         console.log("Value Exist Please add another one");
      //         option.machingId = 0;
      //     }
      // }
      if (option.choId == selectedoption.choId) {
        console.log("Updating the Option", option.choId);
        option.machingId = e.target.value;
      }
    });
    console.log(options);
    this.setState({ currentQuestionOptions: options });
    //console.log(item,'--',isChecked)
    console.log(this.state.currentQuestionOptions);
  }
  handleRatingQuestion(e) {
    console.log(e);
    console.log(e.target.value);
    this.setState({
      ratingValue: e.target.value,
    });
  }
  render() {
    let question = "";
    let submitQuestionMarkup = "";
    if (this.state.currentQuestionType === 1) {
      question = (
        <div>
          <div className="question-container">
            <div className="question-label">
              {this.state.currentQuestionNo + 1}){" "}
              {this.state.currentQuestionText}
            </div>
            <div className="question-answer">
              <FormControl variant="outlined" className="custom-formControl">
                <TextField
                  id="filled-multiline-flexible"
                  label="దయచేసి ఇక్కడ సమాధానం నమోదు చేయండిసమాధానం"
                  multiline
                  rowsMax={8}
                  value={this.blankQuestionAnswer}
                  onKeyPress={(event) => this.handleBlankQuestionAnswer(event)}
                  variant="outlined"
                />
              </FormControl>
            </div>
          </div>
        </div>
      );
    } else if (this.state.currentQuestionType === 2) {
      question = (
        <div>
          <div className="question-container">
            <div className="question-label">
              {this.state.currentQuestionNo + 1}){" "}
              {this.state.currentQuestionText}
            </div>
            <div className="question-answer">
              <FormControl variant="outlined" className="custom-formControl">
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={this.state.selectedOption}
                  onChange={this.handleChooeOneQuestionAnswer}
                >
                  {Array.isArray(this.state.currentQuestionOptions) &&
                    this.state.currentQuestionOptions.map((option) => (
                      <FormControlLabel
                        name={option.choText}
                        value={option.choId}
                        key={option.choId}
                        control={<Radio name={option.choText} />}
                        label={option.choText}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      );
    } else if (this.state.currentQuestionType === 3) {
      question = (
        <div>
          <div className="question-container">
            <div className="question-label">
              {this.state.currentQuestionNo + 1}){" "}
              {this.state.currentQuestionText}
            </div>
            <div className="question-answer">
              <FormControl variant="outlined" className="custom-formControl">
                <FormGroup>
                  {Array.isArray(this.state.currentQuestionOptions) &&
                    this.state.currentQuestionOptions.map((option) => (
                      <FormControlLabel
                        value={option.choId}
                        key={option.choId}
                        onChange={this.handleChooseFollowingQuestionAnswer}
                        control={<Checkbox color="primary" />}
                        label={option.choText}
                        labelPlacement="end"
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      );
    } else if (this.state.currentQuestionType === 4) {
      question = (
        <div>
          <div className="question-container">
            <div className="question-label">
              {this.state.currentQuestionNo + 1}){" "}
              {this.state.currentQuestionText}
            </div>
            <div className="question-answer">
              <div className="row mt-4">
                {Array.isArray(this.state.currentQuestionOptions) &&
                  this.state.currentQuestionOptions.map((option) => (
                    <div className="col-sm-12" key={option.choId}>
                      <div className="left-container">{option.choText}</div>
                      <div className="right-container">
                        <FormControl
                          variant="outlined"
                          className="custom-formControl"
                        >
                          <Select
                            id="ageSelect"
                            label="Age"
                            value={option.machingId}
                            onChange={this.handleMatchingQuestion.bind(
                              this,
                              option
                            )}
                          >
                            {Array.isArray(this.state.currentQuestionRatings) &&
                              this.state.currentQuestionRatings.map(
                                (rating) => (
                                  <MenuItem
                                    value={rating?.ratId}
                                    key={rating?.ratId}
                                  >
                                    {rating?.ratText}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.currentQuestionType === 5) {
      question = (
        <div>
          <div className="question-container">
            <div className="question-label">
              {this.state.currentQuestionNo + 1}){" "}
              {this.state.currentQuestionText}
            </div>
            <div className="question-answer">
              <FormControl variant="outlined" className="custom-formControl digitelex-rating-control">
                <div className={stylesNew}>
                  <Rating
                    name="size-large"
                    defaultValue={0}
                    precision={0.5}
                    size="large"
                    onChange={this.handleRatingQuestion}
                  />
                </div>
              </FormControl>
            </div>
          </div>
        </div>
      );
    } else {
      question = <div className="question-label">No Questions Found</div>;
    }
    if (this.state.isRedirectDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    if (this.propData?.surveyDetails) {
       if ((this.state?.currentQuestionNo+1) == (this.state?.totalQuestions)) {
      submitQuestionMarkup = (
        <FormControl variant="outlined" className="custom-formControl">
          
          <Button
            onClick={this.submitQuestion}
            variant="contained"
            color="primary"
          >
            Complete
          </Button>
        </FormControl>
      );
       }
       else{
          submitQuestionMarkup = (
            <FormControl variant="outlined" className="custom-formControl">
              <Button
                onClick={this.submitQuestion}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </FormControl>
          );
       }
    } else {
      submitQuestionMarkup = (
        <FormControl variant="outlined" className="custom-formControl">
          <Button
            onClick={this.redirectDashboard}
            variant="contained"
            color="primary"
          >
            Back to Dashoard
          </Button>
        </FormControl>
      );
    }
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              edge="start"
               color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6">
              Survey- {this.state.surveyDetails?.surName} (
              {this.state?.currentQuestionNo + 1}/{this.state?.totalQuestions})
            </Typography>
           
          </Toolbar>
        </AppBar>
        <div className="row col-sm-12 mt-3">
          <div className="col-sm-12">{question}</div>
          <div className="col-sm-12">{submitQuestionMarkup}</div>
        </div>
      </div>
    );
  }
}

export default SurveyQuestions;
