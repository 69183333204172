import React, { Component } from "react";
import "./login.css";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Redirect, Link } from "react-router-dom";

import { CONSTANTS } from "../../utils/constant";

import { useHistory } from "react-router-dom";

const { TOOLS } = CONSTANTS;
const { API } = CONSTANTS;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errorUsername: "",
      errorPassword: "",
      error: "",
      errorMessage: "",
      isLoggedIn: "",
    };
    this.usernameChange = this.usernameChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.login = this.login.bind(this);
    this.handleLoginResponse = this.handleLoginResponse.bind(this);
  }
  componentDidMount() {
    // verify localStorage
    const localData = localStorage.getItem("Viswas@React_app");
    if (localData && !localData.session_Token) {
      // console.log("User Logged In");
      this.setState({
        isLoggedIn: true,
      });
    } else {
      // console.log("User Not Logged In");
      this.setState({
        isLoggedIn: false,
      });
    }
  }

  usernameChange(event) {
    //   console.log(event.target.value);
    const value = event.target.value;
    // console.log(value);
    this.setState({
      errorUsername: value && value.length > 6 ? "" : "Invalid Username",
    });
    this.setState({ username: value });
  }

  passwordChange(event) {
    const value = event.target.value;
    this.setState({
      errorPassword:
        value && value.length > 5 ? "" : "Password must be atleast 6 chars",
    });
    this.setState({ password: value });
  }

  loginSubmit(event) {

    // console.log("Clicked on login");
    const username = this.state.username.trim();
    const { password } = this.state;
    let isValid = true;
    if (username === undefined || username == null || username.length <= 4) {
      this.setState({ errorUsername: "Invalid UserName " });
      isValid = false;
      // console.log("User Name");
    }
    // console.log(isValid);

    if (!password || password.length < 6) {
      // this.setState({error: true});
      this.setState({ errorPassword: "Password must be atleast 6 chars" });
      isValid = false;
      // console.log("Password");
    }
    // console.log("At Login Method Calling");
    
    isValid && this.login();
    if(!isValid){
      // console.log("Not Valid");
      this.setState({ error: true, errorMessage: 'Please Enter Username and Password!' });
    }
  }

  login() {
    // console.log(TOOLS);
    const url = TOOLS.getUrl(API.user.login);
    // console.log("URL", url);

    // this.setState({ displayLoader: true });
    const _this = this;
    TOOLS.post(
      url,
      {
        username: this.state.username,
        password: this.state.password,
        device_token: "WEB",
        device_type: "WEB",
        deviceType: "5",
        Lat: "0.0",
        Lng: "0.0",
        fcmCode: "ABCD",
        deviceNo: "1234567",
      },
      ""
    )
      .then(function (responseObj) {
        return responseObj.json();
      })
      .then(function (response) {
        // console.log("Response to Set Play@At_ADMIN", response);
        _this.handleLoginResponse(response);
      });
  }

  handleLoginResponse(response) {
    // console.log(response);
    if (response.status === "Success" || response.status === "ok") {
      localStorage.clear();
      localStorage.setItem("Viswas@React_app", JSON.stringify(response.data));
      this.setState({
        isLoggedIn: true,
      });
    } else {
      this.setState({ error: true, errorMessage: "Invalid Login" });
    }
  }

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <div className="container-fluid no-gutters p-0">
        <div className="login-container">
          <div className="login-area">
            <div className="login-header" />
            <div className="login-wrapper">
              <div className="login-box is-left ">
                <div className="form-wrapper">
                  <h3> Login </h3>
                  <p>
                    {' '}
                    This is a secure system and you will need to provide your
                    login details to access this site.{' '}
                  </p>
                  <form>
                    <div className="form-group">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        autoFocus
                        value={this.state.username}
                        onChange={this.usernameChange}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            // console.log("Enter key pressed");
                            this.loginSubmit();
                          }
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={this.passwordChange}
                        autoComplete="current-password"
                        value={this.state.password}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            // console.log("Enter key pressed");
                            this.loginSubmit();
                          }
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="rememberMe"
                        id="rememberMe"
                      />
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                    </div>
                    <span style={{ color: 'red',fontSize:'12px' }}>
                      {this.state.errorMessage}
                    </span>

                    <div className="form-group">
                      <button type="button" onClick={this.loginSubmit}>
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="login-box is-right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
