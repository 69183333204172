import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";

import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import SurveyHome from "./components/surveyHome/SurveyHome";
import SurveyQuestions from "./components/questions/SurveyQuestions";
import "./App.css";
import SurveyComplete from "./components/surveycomplete/SurveyComplete";
import {BrowserView, MobileView} from 'react-device-detect';
import { isMobile } from 'react-device-detect';

const history: History = createHistory();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.requireAuth = this.requireAuth.bind(this);
  }
  requireAuth(nextState, replace) {
    const userLocalStorage = localStorage.getItem("Viswas@React_app");
    if (!userLocalStorage) {
      console.log("Not Logged iN");
      replace("/login");
    } else {
      console.log("Looged in user");
    }
  }

  render() {
    console.log(isMobile);
    console.log(BrowserView);
    //  if (isMobile) {
       return (
         <Router history={createHistory}>
           <Route exact path="/" component={Dashboard} />
           <Route path="/survey/" component={SurveyHome} />
           <Route path="/sureyquestions/" component={SurveyQuestions} />
           <Route path="/surveycomplete" component={SurveyComplete} />
           <Route path="/login/" component={Login} />
         </Router>
       );
    //  }else
    //  {
    //     return (
    //       <h1>
    //         <center>Please Open the App in Mobile</center>
    //       </h1>
    //     ); 
    //  }
   
  }
}

export default App;
