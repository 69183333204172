// Baseurl for website
// const BASEURL = "http://127.0.0.1:8000/api/";
const BASEURL = "https://viswasapi.digitelex.in/api/";

// Apis
const API = {
  user: {
    login: "authUser",
    dashboard: "dashboard",
    questions: "questions",
  },
  masterData: {
    questionTypes: "questionTypes",
    ages: "ages",
    gender: "gender",
    education: "education",
    occupation: "occupation",
    income: "income",
    religion: "religion",
    caste: "caste",
    subcaste: "subcaste",
    district: "district",
    pcs: "pcs",
    acemblys:"acembly",
    mandals:"mandals",
    villages:"villages",
    booths:`booths`,
    getVoterList:'voterlist',
    acembly: (typeId, id) => `acembly/${typeId}/${id}`,
  },

  questions: "questions",
  answer: "answer",
  finishSurvey: "finalSubmitSample",
};

// Utilities for Website
const TOOLS = {
  getUrl(url) {
    console.log("URL Check :", BASEURL, url);
    return BASEURL + url;
  },

  post(url, body, token) {
    const request = new Request(url, {
      method: "post",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/json; charset=utf-8",
        token: token,
      },
      body: JSON.stringify(body),
    });

    return fetch(request);
  },
  get(url, token) {
    const request = new Request(url, {
      method: "get",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        "Content-Type": "application/json; charset=utf-8",
        token: token,
      },
    });

    return fetch(request);
  },
};

export const CONSTANTS = {
  BASEURL: BASEURL,
  API: API,
  TOOLS: TOOLS,
};
