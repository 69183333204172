import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import "./SurveyHome.css";
import { CONSTANTS } from "../../utils/constant";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Redirect, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

const { TOOLS } = CONSTANTS;
const { API } = CONSTANTS;
 
class SurveyHome extends Component {
  constructor(props) {
    super(props);
    console.log('Entered into Survey Componenet');
    console.log(this.props);

    this.propData = this.props.location.state;
    this.inputRef = React.createRef();

    this.state = {
      mSurveysList: '',
      mAgesList: '',
      votersList: '',
      acsList: '',
      mandalsList: '',
      villagesList: '',
      boothList:'',
       surId: '',
      Lat: '16.28.',
      Lng: '80.258',
      deviceNo: '1234',
      genId: '',
      perId: '',
      ageId: '',
      eduId: '',
      occId: '',
      incId: '',
      relId: '',
      casteId: '',
      subCastId: '',
      name: '',
      mobileNo: '',
      voterId: '',
      districtId: '',
      pcId: '',
      vId:'',
      acemblyId: JSON.parse(localStorage.getItem('Viswas@React_app')).acemblyId,
      mandalId: null,
      villageId: '',
      boothId: '',
      isStartedSurvey: false,
    };
    this.handleSurvey = this.handleSurvey.bind(this);
    this.handlevillage = this.handlevillage.bind(this);
    this.handelMandal = this.handelMandal.bind(this);
    this.handleAge = this.handleAge.bind(this);
    this.handleGender = this.handleGender.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleMobileNo = this.handleMobileNo.bind(this);
    this.handleEducation = this.handleEducation.bind(this);
    this.handleOccupation = this.handleOccupation.bind(this);
    this.handleIncome = this.handleIncome.bind(this);
    this.handleReligion = this.handleReligion.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleVoter = this.handleVoter.bind(this);

    this.handleSubCaste = this.handleSubCaste.bind(this);
    this.handleBooth = this.handleBooth.bind(this);
    this.getAgesList = this.getAgesList.bind(this);
    this.getGenderList = this.getGenderList.bind(this);
    this.getEducationList = this.getEducationList.bind(this);
    this.getOccupationList = this.getOccupationList.bind(this);
    this.getIncomeList = this.getIncomeList.bind(this);
    this.getReligionList = this.getReligionList.bind(this);
    this.getCasteList = this.getCasteList.bind(this);
    this.getSubCasteList = this.getSubCasteList.bind(this);
    this.getAcList = this.getAcList.bind(this);
    this.getMandalList = this.getMandalList.bind(this);
    this.getBoothList = this.getBoothList.bind(this);
    this.getVoters = this.getVoters.bind(this);
    this.startSurvey = this.startSurvey.bind(this);
    this.getAcList();
  }

  componentDidMount() {
   
    this.getAgesList();
    this.getGenderList();
    this.getEducationList();
    this.getOccupationList();
    this.getIncomeList();
    this.getReligionList();
    this.getCasteList();
    this.getSubCasteList();
  
    this.getMandalList();
    this.getBoothList();

    this.setState({
      surId: this.propData.id.surveyId,
      mSurveysList: this.propData.surveys,
    });
  }
  getVillageList(e) {
    console.log(this.state);
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.villages);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url + `/4/${e}`,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              villagesList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getAcList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.acemblys);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              acsList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getBoothList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.booths);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url +
        `/${JSON.parse(localStorage.getItem('Viswas@React_app')).acemblyId}`,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
    // TOOLS.get(
    //   url,
    //   JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    // )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              boothList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getMandalList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.mandals);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url +
        `/3/${JSON.parse(localStorage.getItem('Viswas@React_app')).acemblyId}`,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mandalsList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAgesList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.ages);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mAgesList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getGenderList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.gender);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Gender Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mGenderList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getEducationList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.education);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Education Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mEducationList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getOccupationList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.occupation);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Occupation Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mOccupationList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getIncomeList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.income);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Income Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mIncomeList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getReligionList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.religion);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Religion Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mReligionList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCasteList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.caste);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Caste Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              mCasteList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getSubCasteList() {
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.subcaste);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('SubCaste Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              masterSubCasteList: jsonResponse.Data,
              mSubCasteList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSurvey(e) {
    console.log(e);
    this.setState({
      surId: e.target.value,
    });
  }
  handlevillage(e){
    console.log(e);
    this.setState({
      villageId: e.target.value,
    });
  }
  handelMandal(e) {
    console.log(e);
    this.setState({
      mandalId: e.target.value,
    });
    console.log(this.state.mandalId);
    this.getVillageList(e.target.value);
  }
  handleName(e) {
    console.log(e);
    console.log(e.target.name);

    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state);
      }
    );
    // console.log(this.state);
  }
  handleMobileNo(e) {
    console.log(e.target.name);

    this.setState({
      mobileNo: e.target.value,
    });
  }
  handleAge(e) {
    console.log(e);
    this.setState({
      ageId: e.target.value,
    });
  }
  handleGender(e) {
    console.log(e);
    this.setState({
      genId: e.target.value,
    });
  }
  handleEducation(e) {
    console.log(e);
    this.setState({
      eduId: e.target.value,
    });
  }
  handleOccupation(e) {
    console.log(e);
    this.setState({
      occId: e.target.value,
    });
  }
  handleIncome(e) {
    console.log(e);
    this.setState({
      incId: e.target.value,
    });
  }
  handleReligion(e) {
    console.log(e);
    this.setState({
      relId: e.target.value,
    });
  }
  handleVoter(e) {
    console.log(e);
    this.setState({
      vId: e.target.value,
      name :e.nativeEvent.target.innerText

    });
    

  }

  handleCaste(e) {
    console.log(e);
    this.setState({
      casteId: e.target.value,
    });
    console.log(this.state);
    const data = JSON.parse(JSON.stringify(this.state.masterSubCasteList));
    this.setState({
      mSubCasteList: data.filter(
        (subCaste) => subCaste.casteId === e.target.value
      ),
    });
  }
  handleSubCaste(e) {
    console.log(e);
    this.setState({
      subCastId: e.target.value,
    });
  }
  handleBooth(e) {
    console.log(e);
    this.setState({
      boothId: e.target.value,
    });
    this.getVoters(e.target.value);
  }
  getVoters(e){
    console.log(e);
    const _this = this;
    const url = TOOLS.getUrl(API.masterData.getVoterList);
    console.log('URL', url);
    // this.setState({ displayLoader: true });
    TOOLS.get(
      url +
        `/${this.propData.id.surveyId}/${JSON.parse(localStorage.getItem('Viswas@React_app')).acemblyId}/${e}`,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log('Search Response', jsonResponse);
        if (jsonResponse && jsonResponse.status === 'Success') {
          this.setState(
            {
              votersList: jsonResponse.Data,
            },
            () => console.log('Updated Data:', this.state.data)
          );
        } else {
          console.log('No Data');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  startSurvey(e) {
    console.log(e);
    console.log('Start Survey');
    // console.log(TOOLS);
    const url = TOOLS.getUrl(API.user.questions);
    console.log('URL', url);

    // this.setState({ displayLoader: true });
    const _this = this;
    const obj = {
      surId: this.state.surId,
      Lat: this.state.Lat,
      Lng: this.state.Lng,
      deviceNo: this.state.deviceNo,
      genId: this.state.genId,
      perId: 0,
      ageId: this.state.ageId,
      eduId: this.state.eduId,
      occId: this.state.occId,
      incId: this.state.incId,
      relId: this.state.relId,
      casteId: this.state.casteId,
      subCastId: this.state.subCastId,
      mobileNo: this.state.mobileNo,
      voterId: this.state.voterId,
      districtId: this.state.districtId,
      pcId: this.state.pcId,
      acemblyId: this.state.acemblyId,
      mandalId: this.state.mandalId,
      villageId: this.state.villageId,
      boothId: this.state.boothId,
      vid:this.state.vId
    };
     TOOLS.post(
      url,
      obj,
      JSON.parse(localStorage.getItem('Viswas@React_app')).session_Token
    )
      .then(function (responseObj) {
        return responseObj.json();
      })
      .then(function (response) {
        console.log('Response to Set Play@At_ADMIN', response);
        console.log(response);
        _this.setState({
          surveyDetails: response,
          isStartedSurvey: true,
        });
      });
  }
  render() {
    if (this.state.isStartedSurvey) {
      return (
        <Redirect
          to={{
            pathname: '/sureyquestions',
            state: { surveyDetails: this.state.surveyDetails },
          }}
        />
      );
    }
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              edge="start"
               color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6">Survey</Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              style={{ position: 'absolute', right: 0 }}
              onClick={() => {
                this.props.history.push('/');
              }}
            >
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          className="row col-sm-12 mt-2"
          style={{ height: '100vh', overflowY: 'scroll' }}
        >
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Survey</InputLabel>
              <Select
                id="SurveyMenu"
                value={this.state.surId}
                onChange={this.handleSurvey}
                onClick={this.handleSurvey}
                onOpen={this.handleSurvey}
                label="Survey"
              >
                {Array.isArray(this.state.mSurveysList) &&
                  this.state.mSurveysList.map((survey) => (
                    <MenuItem
                      value={survey.surveyId}
                      key={survey.surveyId}
                      name={survey.surName}
                    >
                      {survey.surName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>AC</InputLabel>
              <Select
                disabled
                id="acSelect"
                value={this.state.acemblyId}
                label="AC"
              >
                {Array.isArray(this.state.acsList) &&
                  this.state.acsList.map((ac) => (
                    <MenuItem value={ac.acemblyId} key={ac.acemblyId}>
                      {ac.acemblyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Mandals</InputLabel>
              <Select
                id="mandalSelect"
                value={this.state.mandalId}
                onChange={this.handelMandal}
                label="Mandal"
              >
                {Array.isArray(this.state.mandalsList) &&
                  this.state.mandalsList.map((mandal,i) => (
                    <MenuItem value={mandal.mandalId} key={i}>
                      {mandal.mandalName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Villages</InputLabel>
              <Select
                id="villageSelect"
                value={this.state.villageId}
                onChange={this.handlevillage}
                label="Village"
              >
                {Array.isArray(this.state.villagesList) &&
                  this.state.villagesList.map((village) => (
                    <MenuItem value={village.villageId} key={village.villageId}>
                      {village.villageName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Booth</InputLabel>
              <Select
                id="villageSelect"
                value={this.state.boothId}
                onChange={this.handleBooth}
                label="Booth"
              >
                {Array.isArray(this.state.boothList) &&
                  this.state.boothList.map((booth) => (
                    <MenuItem value={booth.psName} key={booth.psName}>
                      {booth.psName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Voter</InputLabel>
              <Select
                id="voterSelect"
                value={this.state.vId}
                name="hello"
                onChange={this.handleVoter}
                label="Booth"
              >
                {Array.isArray(this.state.votersList) &&
                  this.state.votersList.map((voter) => (
                    <MenuItem  name={voter.vname} value={voter.vid} key={voter.vid}>
                      {voter.vname}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              {/* <InputLabel id="demo-simple-select-outlined-label">Name</InputLabel> */}
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={this.state.name}
                name="name"
                onChange={this.handleName}
              />
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <TextField
                id="outlined-basic"
                label="Mobile No"
                variant="outlined"
                value={this.state.mobileNo}
                name="mobileNo"
                onChange={this.handleName}
              />
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Gender</InputLabel>
              <Select
                id="ageSelect"
                value={this.state.genId}
                onChange={this.handleGender}
                label="Age"
              >
                {Array.isArray(this.state.mGenderList) &&
                  this.state.mGenderList.map((gender) => (
                    <MenuItem value={gender.genderId} key={gender.genderId}>
                      {gender.genderName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Age</InputLabel>
              <Select
                id="ageSelect"
                value={this.state.ageId}
                onChange={this.handleAge}
                label="Age"
              >
                {Array.isArray(this.state.mAgesList) &&
                  this.state.mAgesList.map((age) => (
                    <MenuItem value={age.id} key={age.id}>
                      {age.num}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Education Type</InputLabel>
              <Select
                id="educationSelect"
                value={this.state.eduId}
                onChange={this.handleEducation}
                label="Education Type"
              >
                {Array.isArray(this.state.mEducationList) &&
                  this.state.mEducationList.map((education) => (
                    <MenuItem
                      value={education.educationId}
                      key={education.educationId}
                    >
                      {education.educationName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Occupation</InputLabel>
              <Select
                id="occupationSelect"
                value={this.state.occId}
                onChange={this.handleOccupation}
                label="Occupation"
              >
                {Array.isArray(this.state.mOccupationList) &&
                  this.state.mOccupationList.map((occupation) => (
                    <MenuItem
                      value={occupation.occupationId}
                      key={occupation.occupationId}
                    >
                      {occupation.occupationName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Income Type</InputLabel>
              <Select
                id="incomeSelect"
                value={this.state.incId}
                onChange={this.handleIncome}
                label="Income Type"
              >
                {Array.isArray(this.state.mIncomeList) &&
                  this.state.mIncomeList.map((incomeType) => (
                    <MenuItem
                      value={incomeType.incomeId}
                      key={incomeType.incomeId}
                    >
                      {incomeType.incomeName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Religion</InputLabel>
              <Select
                id="religionSelect"
                value={this.state.relId}
                onChange={this.handleReligion}
                label="Religion"
              >
                {Array.isArray(this.state.mReligionList) &&
                  this.state.mReligionList.map((religion) => (
                    <MenuItem
                      value={religion.religionId}
                      key={religion.religionId}
                    >
                      {religion.religionName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Caste</InputLabel>
              <Select
                id="casteSelect"
                value={this.state.casteId}
                onChange={this.handleCaste}
                label="Caste"
              >
                {Array.isArray(this.state.mCasteList) &&
                  this.state.mCasteList.map((caste) => (
                    <MenuItem value={caste.casteId} key={caste.casteId}>
                      {caste.casteName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-sm-12">
            <FormControl variant="outlined" className="custom-formControl">
              <InputLabel>Sub Caste</InputLabel>
              <Select
                id="subCasteSelect"
                value={this.state.subCastId}
                onChange={this.handleSubCaste}
                label="Sub Caste"
              >
                {Array.isArray(this.state.mSubCasteList) &&
                  this.state.mSubCasteList.map((subcaste) => (
                    <MenuItem
                      value={subcaste.subCasteId}
                      key={subcaste.subCasteId}
                    >
                      {subcaste.subCasteName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
         
          <div className="col-sm-12" style={{ marginBottom: '5rem' }}>
            <FormControl variant="outlined" className="custom-formControl">
              <Button
                variant="contained"
                color="primary"
                onClick={this.startSurvey}
              >
                Start Survey
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
}

export default SurveyHome;
